import discussionGraph from "providers/discussionGraph";
import D3Component from "components/D3Component";
import { ForceGraph } from "providers/forceGraph";
import { useState, useEffect } from "react";
import ButtonCSV from "components/ButtonCSV";
import GetWords from "components/GetWords";
import './discussion.css';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import SummaryOpenIcon from '@mui/icons-material/MenuOpen';
import WaterMarkLogo from 'assets/512px/Logo-Color.png';
import Highlights from "components/Highlights";
import canvasFetch from "providers/canvasFetch";
import TableCSV from "components/TableCSV";

/**
 * @typedef DiscussionObject
 * @property  {string} course_id the course id of the discussion
 */

//this code represents each discussion post, displays it in a button that you can click on
/**
 * 
 * @param {Object} props The properties coming in to the selection
 * @param {boolean} props.singleDiscussion Is this only displaying one discussion?
 * @param {string} props.title The title of the discussion for the page.
 * @param {string} props.course_id The course id
 * @param {string} props.access_token The magical access token... wooooh 
 * @param {DiscussionObject[]} props.discussions The discussion_topics object
 * @returns 
 */
const DiscussionSelection = function ({ singleDiscussion, title, course_id, access_token, discussions }) {
    //data needed for d3js fancy graph
    const forceGraphOpts = {
        nodeId: d => d.id,
        nodeGroup: d => d.group,
        nodeTitle: d => `${d.id}\n${d.group}`,
        linkStrokeWidth: l => Math.sqrt(l.value),
        width: 1000,
        height: 600
    }

    //This effect will grab discussion views for every single discussion metadata provided both discussions and discussionData will be arrays
    const [discussionData, setDiscussionData] = useState()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(true)
        let dicussionFetches = discussions.map(disc => { console.log(disc); return canvasFetch("courses/" + course_id + "/discussion_topics/" + disc.id + "/view", access_token) })
        Promise.all(dicussionFetches)
            .then(val => {
               setDiscussionData(val);
                setLoading(false)
            })
    }, [discussions])

    //load the data needed for the graph and other stuff
    const [discussionGraphData, setDiscussionGraphData] = useState(null);
    useEffect(() => {
        if (discussionData) {
            setDiscussionGraphData(discussionGraph(discussionData, discussions))
        }
    }, [discussionData]);


    const [forceGraphElement, setForceGraphElement] = useState();
    useEffect(() => {
        if (discussionGraphData) {
            setForceGraphElement(ForceGraph(discussionGraphData, forceGraphOpts))
        }
    }, [discussionGraphData])


    console.log(discussionGraphData);
    //actual data for each discussion post that is shown, csv is not shown for combined data as it doesnt make sense
    //TODO fix bug where new data does not render correctly and you need to close the button and reopen it
    return (<Box sx={{
        width: "70vw",
        textAlign: "start",
        minHeight: '365px',
        backgroundImage: `url(${WaterMarkLogo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 35px',
        backgroundSize: 'contain'
    }}>
        <Typography variant="h3" sx={{ fontWeight: "700", textAlign: "start" }}>Discussion: {discussions.length === 1 ? discussions[0].title : "All"} </Typography>
        <Card sx={{
            backgroundColor: `transparent`,
            boxShadow: `0px 0px 0px 0px rgba(0,0,0,0.0)`
        }}>
            {loading && <CardContent sx={{textAlign: "center"}}><CircularProgress/></CardContent>}
            {discussionData && !loading &&
                <CardContent sx={{

                    ".MuiPaper-root": {
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        "&.Mui-expanded": {
                            backgroundColor: 'rgba(255,255,255,1.0)'
                        }
                    },

                    ".MuiAccordionSummary-root": {
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        "&:hover": {
                            backgroundColor: (theme) => {return theme.palette.primary.light},
                            color: `#FFFFFF` //white text
                        }
                    },
                    ".MuiButtonBase-root.Mui-expanded": {
                        backgroundColor: (theme) => {return theme.palette.secondary.main},
                        color: `#FFFFFF` //white text
                    }
                }}>
                   <Accordion>
                        <AccordionSummary>
                            <Typography>
                                Highlights and Summary <SummaryOpenIcon sx={{color: `#FFFFFF`, position: `absolute`, right: `10px`}} />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {discussionGraphData &&
                                <Highlights graphObj={discussionGraphData} discussion={discussionData[0]} />
                            }
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>
                            <Typography>
                                CSV Export and Table <SummaryOpenIcon sx={{color: `#FFFFFF`, position: `absolute`, right: `10px`}} />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ overflowX: "scroll" }}>
                            <TableCSV discussion={discussionData[0]} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>
                            <Typography>
                                Word Cloud <SummaryOpenIcon sx={{color: `#FFFFFF`, position: `absolute`, right: `10px`}} />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <GetWords discussion={discussionData[0]} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>
                            <Typography>
                                Graph of {title} <SummaryOpenIcon sx={{color: `#FFFFFF`, position: `absolute`, right: `10px`}} />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {forceGraphElement &&
                                <D3Component element={forceGraphElement} />
                            }
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            }
        </Card>

        <Card sx={{
            backgroundColor: `rgba(3, 83, 8, 0.29)`
        }}>
            <CardContent>
                {discussionData &&
                <ButtonCSV disabled={loading} discussion={discussionData[0]} title={discussions[0].title.replace(/\W*/g, "")} />
                }
            </CardContent>
        </Card>
    </Box>)
}


export default DiscussionSelection;
