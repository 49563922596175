import {
    Autocomplete,
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography
} from "@mui/material";
import { useTheme } from "@emotion/react";
import ListSearchIcon from '@mui/icons-material/ManageSearch';
import SearchLoadingIcon from '@mui/icons-material/FindReplace';
import SearchEmptyIcon from '@mui/icons-material/SearchOff';
import CloseIcon from "@mui/icons-material/Close";
import ArrowRightIcon from "@mui/icons-material/ArrowForwardIos";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import canvasFetch from "../../providers/canvasFetch";
import SearchAutoCompleteDataFilter from "../SearchAutoCompleteDataFilter";
import DatumListItemButton from "../DatumListItemButton";

export default function QuizNavList(props) {
    const [selectedIndex, setSelectedIndex] = useState(-1) // For list buttons. '-1' means no button starts selected

    const [filteredData, setFilteredData] = useState([]) // The filtered down quizzes based on the quiz search input text given

    const [activeRoute, setActiveRoute] = useState('/') // Track the state of the current route to the Layout Outlet

    let navigate = useNavigate();
    let location = useLocation();

    // Route Navigation connected with Layout Outlet
    useEffect(() => {

        navigate(activeRoute, {state: {from: location}})

    }, [activeRoute]) // when the active route is changed (a list button is selected/deselected), navigate to the new one!

    useEffect( () => {

        setFilteredData(props.dataObject)

    }, [props.dataObject])

    return ( // The quiz button list that is initially rendered holding all filteredData elements and is re-rendered when filteredData changes

        <>
            <ListSearchIcon sx={{ color: 'action.active', mr: 0, my: 1.5 }} />

            <SearchAutoCompleteDataFilter dataLabel={"Quiz"} dataObject={props.dataObject} setSelectedDatumObject={props.setSelectedDatumObject}
                                          setFilteredData={setFilteredData} setSelectedIndex={setSelectedIndex}
                                          setActiveRoute={setActiveRoute} />

            <List component="nav" aria-label="list of quizzes and their titles in selectable buttons"

                  sx={{
                      direction: `rtl`, // place scrollbar on left side so the data title buttons don't point into the scrollbar
                      overflowY: `auto`, // add a scrollbar
                      height: `60vh`, // 60% of relative viewport height (vh)
                      padding: `0px`,
                      marginBottom: `20px`,
                      scrollSnapType: `y proximity`,

                      ".MuiButtonBase-root": { // For list buttons when it is not selected/active
                          "&:hover": {
                              color: `#ffffff`,
                              backgroundColor: (theme) => { return theme.palette.primary.light }
                          }
                      },

                      ".MuiListItemButton-root.Mui-selected": { // For list buttons when it is selected/active
                          color: `#ffffff`,
                          backgroundColor: (theme) => { return theme.palette.primary.light },
                          "&:hover": { // Specifically, when a list button is hovered over (the ampersand is for the current parent elemnt)
                              backgroundColor: (theme) => { return theme.palette.primary.main }
                          }
                      }
                  }}>

                    <div style={{
                        direction: `ltr` // render content normally but keep scrollbar on the left side so the data title buttons don't point into the scrollbar
                    }}>

                    { props.dataObject.length === 0 ? <Typography sx={{ // displays when discussions are loading from the current course
                            backgroundColor: (theme) => { return theme.palette.secondary.main },
                            padding: `8px`,
                            paddingTop: `24px`,
                            paddingBottom: `24px`,
                            textAlign: `center`,
                            color: `#FFFFFF`
                        }} variant="h5" component="div">
                            <SearchLoadingIcon sx={{ color: `#FFFFFF`, mr: 1.5, my: 0 }} />Loading quizzes...
                        </Typography>
                        :
                        (filteredData.length === 0 && props.dataObject.length !== 0) ? <Typography sx={{ // displays when no quizzes are available from the current course
                                backgroundColor: (theme) => { return theme.palette.secondary.dark },
                                padding: `8px`,
                                paddingTop: `24px`,
                                paddingBottom: `24px`,
                                textAlign: `center`,
                                color: `#FFFFFF`
                            }} variant="h5" component="div">
                                <SearchEmptyIcon sx={{ color: `#FFFFFF`, mr: 1.5, my: 0 }} />No quizzes returned
                            </Typography>
                            :
                            filteredData.map((quiz, index) => // for each quiz render a list button for it

                                quiz.title !== undefined && // don't try to render with no value (throws error), render when there is a value

                                <DatumListItemButton dataLabel={"Quiz"} currentDatumObject={quiz}
                                                     setSelectedDatumObject={props.setSelectedDatumObject}
                                                     selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}
                                                     activeRoute={activeRoute} setActiveRoute={setActiveRoute} />
                            )}
                </div>
            </List>
        </>
    )
}