import {ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowRightIcon from "@mui/icons-material/ArrowForwardIos";
import {useState} from "react";

export default function DatumListItemButton(props) {

    const [mouseFocus, setMouseFocus] = useState(false) // Track the state of the mouse over list buttons

    return(

        <ListItemButton

            key={props.currentDatumObject.id}

            selected={props.selectedIndex === props.currentDatumObject.id} // maps the selection of this list button with a discussion/quiz/datum

            onClick={event => {
                setMouseFocus(true) // mouse is for sure in the button; avoids wrong icon showing when activated

                // the selected index is unique to a mapped discussion/quiz/datum with each new button for the list of buttons (setting the state to '-1' means deselect the already selected button)

                if (props.activeRoute === '/') { // we are in the Home route in this case when button is clicked

                    props.setSelectedIndex(props.currentDatumObject.id) // make the clicked button selected
                    props.setSelectedDatumObject(props.currentDatumObject) // set current discussion/quiz/datum as the selected one
                    props.setActiveRoute(`${props.dataLabel}View/${props.currentDatumObject.id}`) // switch to the DiscussionView/QuizView route with the associated id context

                } else { // we are in the DiscussionView route in this case when button is clicked

                    if (props.selectedIndex === props.currentDatumObject.id) { // the button clicked is selected already when clicked

                        props.setSelectedIndex(-1) // make the clicked button deselected
                        props.setSelectedDatumObject(null) // clear the current discussion/quiz/datum from being the selected datum for the current data view
                        props.setActiveRoute('/') // go back to the Home route

                    } else if (props.selectedIndex !== props.currentDatumObject.id) { // button clicked is not selected yet when clicked

                        props.setSelectedIndex(props.currentDatumObject.id) // make clicked button selected instead of the previous one
                        // we are already in DiscussionView/QuizView/some sort of data view route here
                        props.setSelectedDatumObject(props.currentDatumObject) // set current discussion/quiz/datum as the selected one
                        props.setActiveRoute(`${props.dataLabel}View/${props.currentDatumObject.id}`) // update the id context for the current data view (DiscussionView/QuizView) route
                    }
                }
            }}

            onMouseEnter={event => { if (props.selectedIndex === props.currentDatumObject.id) {setMouseFocus(!mouseFocus)} }} // mouse has entered button element (ignores children elements firing same event unlike onMouseOver)
            onMouseLeave={event => { if (props.selectedIndex === props.currentDatumObject.id) {setMouseFocus(!mouseFocus)} }} // mouse has left button element (ignores children elements firing same event unlike onMouseOut)

            sx={{
                // "styles xtra" for specifying CSS rules for this MUI element plus other MUI extras
                // add as needed
                scrollSnapAlign: `start`
            }}>

            <ListItemText primary={props.currentDatumObject.title} /> {/*// title text that goes onto the buttons */}

            {(props.selectedIndex === props.currentDatumObject.id && mouseFocus) ? // if the button is both active/selected and has mouse over it
                <ListItemIcon sx={{
                    minWidth: `0px`,
                    color:`#ffffff` /* pure white */ }}>
                    <CloseIcon />
                </ListItemIcon>
                : // else
                (props.selectedIndex === props.currentDatumObject.id) && // if the button is active/selected and has no mouse over it
                <ListItemIcon sx={{
                    minWidth: `0px`,
                    color:`#ffffff` /* pure white */ }}>
                    <ArrowRightIcon />
                </ListItemIcon>}

        </ListItemButton>
    )
}