// imports
import surveySubmissions from "../../providers/surveySubmissions";
import './table.css';
import React, { useEffect, useState } from "react";
import { CsvToHtmlTable } from "react-csv-to-table";
import canvasFetch from "providers/canvasFetch";
import { Parser } from "json2csv";

const SurveyCSV = function (props) {

    // state for the survey data
    const [surveyData, setSurveyData] = useState(null);
    useEffect(() => {
        surveySubmissions(props.course_id, props.assignment_id, props.quiz_id, props.access_token)
            .then(value => {
                setSurveyData(value);
            });
    }, [props.access_token, props.assignment_id, props.quiz_id, props.course_id]);
    // console.log(surveyData)

    // creates the state for the .CSV table
    const [display, setDisplay] = useState(false);
    const [title, setTitle] = useState(false);
    const [statistics, setStatistics] = useState(null);
    let file = null;
    // taking in certain keys and changing their labels
    const fields = [];

    // regex to remove HTML
    const removeHTML = /<[^>]+>/g;
    // regex to remove commas
    const removeCommas = /,/g;
    // regex to remove &nbsp;
    const removeNBSP = /(&nbsp;)*/g;

    useEffect(() => {
        // GET quiz statistics
        canvasFetch("courses/" + props.course_id + "/quizzes/" + props.quiz_id + "/statistics", props.access_token)
            .then(value => {

                // get the question statistics
                setStatistics(value.quiz_statistics[0].question_statistics);

            });
    }, [props.course_id, props.quiz_id, props.access_token])

    // populate the fields with the questions
    try {

        // if there are no submissions
        if (statistics.length === 0) {
            return "There are no submissions."
        }

        // default User ID data
        fields.push({
            label: 'User ID', value: "user_id"
        });

        statistics.forEach(value => {
            // remove HTML tags
            value.question_text = value.question_text.replace(removeHTML, '');
            // remove commas
            value.question_text = value.question_text.replace(removeCommas, '');
            // remove &nbsp
            value.question_text = value.question_text.replace(removeNBSP, '');

            // add to fields
            fields.push(new MakeFields(value.question_text, value.question_text));
        });

        // console.log(fields);

        const parser = new Parser({ fields });
        file = parser.parse(surveyData);
        // console.log(file);

    }
    // if empty, return null
    catch (empty) {
        return null;
    }
    // console.log(fields);

    // console.log(statistics)

    // onClick to show the CSV table
    const onClick = () => {
        // onClick, display the title of the survey
        title ? setTitle(false) : setTitle(true);
        // onClick, display the CSV table
        display ? setDisplay(false) : setDisplay(true);

    }

    return (

        <>
            <h3 className={"survey-title"}>CSV Table for: {props.survey_title}</h3>
            <CsvToHtmlTable tableClassName={"survey-table"} data={file} csvDelimiter={","} />
        </>

    )

}

/**
 * Function that makes the the labels for the table
 * @param label the label for the row
 * @param value the value taken in for the row
 */
function MakeFields(label, value) {
    this.label = label;
    this.value = value;
}

export default SurveyCSV;