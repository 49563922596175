
import {getTheCourseSnapshot} from "./courseSnapshot";

/**
 * This is a class for reading the course snapshot data from a json object. This is needed to read the mapped data stored in a file.
 * This is used to make the api requests to a local file, instead of to the canvas api directly
 * @param {string} apiPath Path ending for accessing at the api. The result should be stored in the file
 * 
 * @returns {Object} JSON object of the response to the request
 */

const readCourseSnapshot = function (apiPath) {
    const courseSnapshot = getTheCourseSnapshot()
    if (courseSnapshot){

        //remove trailing "/" from saved api requests as they are not saved in the file
        apiPath = apiPath.replace(/\/$/gm, "")
        //console.log(courseSnapshot)
        var theResult = courseSnapshot.get(apiPath);
        //console.log("the result for : " + apiPath)
        //console.log(theResult);

        if (theResult === null || theResult === undefined) {
            console.warn("no result for: " + apiPath)
        }
        return theResult
    }
    else{
        console.warn("ERROR no course snapshot loaded")
        return null
    }
}

export default readCourseSnapshot;