

import { Typography } from "@mui/material";
import { avgPart, maxClosenessCo, maxClusterCo, maxNumEdge, maxPosts, numStudents } from "providers/formulas";
import React, { useState, useEffect } from "react";

// Left for reference of the shape of the object.
const constGraphObj = {
  "nodes": [
    {
      "id": "rootPost 0: Chistmas Test",
      "group": 2
    },
    {
      "id": "Josh Osmanski",
      "group": 1
    },
    {
      "id": "rootPost 1: demo test",
      "group": 2
    },
    {
      "id": "Viji Ramasamy",
      "group": 1
    },
    {
      "id": "rootPost 2: Test 2 Electric Boogaloo",
      "group": 2
    },
    {
      "id": "rootPost 3: test discussion",
      "group": 2
    },
    {
      "id": "Jason Prado",
      "group": 1
    },
    {
      "id": "rootPost 4: Virtual Learning",
      "group": 2
    },
    {
      "id": "Nicholas Molina",
      "group": 1
    },
    {
      "id": "Kyle Johnson",
      "group": 1
    },
    {
      "id": "Noah Bloom",
      "group": 1
    },
    {
      "id": "rootPost 5: Hello there... ",
      "group": 2
    }
  ],
  "links": [
    {
      "source": "Josh Osmanski",
      "target": "rootPost 0: Chistmas Test",
      "value": 1
    },
    {
      "source": "Josh Osmanski",
      "target": "Josh Osmanski",
      "value": 1
    },
    {
      "source": "Josh Osmanski",
      "target": "rootPost 1: demo test",
      "value": 1
    },
    {
      "source": "Viji Ramasamy",
      "target": "rootPost 1: demo test",
      "value": 1
    },
    {
      "source": "Josh Osmanski",
      "target": "rootPost 2: Test 2 Electric Boogaloo",
      "value": 1
    },
    {
      "source": "Jason Prado",
      "target": "rootPost 3: test discussion",
      "value": 1
    },
    {
      "source": "Viji Ramasamy",
      "target": "rootPost 3: test discussion",
      "value": 1
    },
    {
      "source": "Josh Osmanski",
      "target": "rootPost 4: Virtual Learning",
      "value": 1
    },
    {
      "source": "Nicholas Molina",
      "target": "rootPost 4: Virtual Learning",
      "value": 1
    },
    {
      "source": "Kyle Johnson",
      "target": "rootPost 4: Virtual Learning",
      "value": 1
    },
    {
      "source": "Noah Bloom",
      "target": "rootPost 4: Virtual Learning",
      "value": 1
    },
    {
      "source": "Kyle Johnson",
      "target": "Viji Ramasamy",
      "value": 2
    },
    {
      "source": "Josh Osmanski",
      "target": "Viji Ramasamy",
      "value": 2
    },
    {
      "source": "Viji Ramasamy",
      "target": "rootPost 5: Hello there... ",
      "value": 1
    },
    {
      "source": "Jason Prado",
      "target": "rootPost 5: Hello there... ",
      "value": 1
    },
    {
      "source": "Josh Osmanski",
      "target": "Kyle Johnson",
      "value": 1
    },
    {
      "source": "Kyle Johnson",
      "target": "rootPost 5: Hello there... ",
      "value": 1
    },
    {
      "source": "Josh Osmanski",
      "target": "rootPost 5: Hello there... ",
      "value": 2
    }
  ]
}

export default function Highlights({ graphObj, discussion }) {

  // Initialize and grab state for all graph statistics.
  const [numStudentsResult, setNumStudentsResult] = useState()
  const [avgPartResult, setAvgPartResult] = useState()
  const [maxNumEdgeResult, setMaxNumEdgeResult] = useState()
  const [maxClusterCoStudent, setMaxClusterCoStudent] = useState()
  const [maxClusterCoResult, setMaxClusterCoResult] = useState()
  const [maxNumPostsStudent, setMaxNumPostsStudent] = useState()
  const [maxNumPostsResult, setMaxNumPostsResult] = useState()
  const [maxClosenessCoStudent, setmaxClosenessCoStudent] = useState()
  const [maxClosenessCoResult, setmaxClosenessCoResult] = useState()
  
  useEffect(() => {
    setNumStudentsResult(numStudents(graphObj))
    setAvgPartResult(avgPart(graphObj))
    setMaxNumEdgeResult(maxNumEdge(graphObj))
    let maxClusterCoObj = maxClusterCo(graphObj)
    setMaxClusterCoStudent(maxClusterCoObj.student)
    setMaxClusterCoResult(maxClusterCoObj.result)
    let maxPostObj = maxPosts(graphObj)
    setMaxNumPostsStudent(maxPostObj.student)
    setMaxNumPostsResult(maxPostObj.result)
    let maxClosenessCoObj = maxClosenessCo(graphObj)
    setmaxClosenessCoStudent(maxClosenessCoObj.student)
    setmaxClosenessCoResult(maxClosenessCoObj.result)
  })

  // Next section for grabbing word counts.
  const [maxWordCount, setMaxWordCount] = useState()
  const [maxWordCountStu, setMaxWordCountStu] = useState()

  useEffect(() => {
    if (discussion) {
      let messageCounts = {}
      discussion.participants.forEach(user => {
        messageCounts[user.id] = { name: user.display_name, count: 0 }
      })
      // course data
      let data = discussion.view;

      // iterates through the course data
      data.forEach(element => {

        // finds the messages
        if (element.message && !element.deleted) {
          // removes the HTML and new lines
          element.message = reformat(element.message);
          // add message to messages
          let count = element.message.split(' ').filter(val => val !== "").length;
          messageCounts[element.user_id].count += count
        }

        // finds the recent replies
        if (element.replies) {
          // iterates through the recent replies
          element.replies.forEach(reply => {
            if (reply.message && !reply.deleted) {
              // removes the HTML and new lines
              reply.message = reformat(reply.message);
              // add reply to messages
              let count = reply.message.split(' ').filter(val => val !== "").length;
              messageCounts[element.user_id].count += count
            }
          })
        }
      });

      let mc = 0
      let mcs = ""

      for (const each of  Object.keys(messageCounts)) {
        let mess = messageCounts[each]
        if (mess.count > mc) {mc = mess.count; mcs = mess.name}
      }
      setMaxWordCount(mc)
      setMaxWordCountStu(mcs)
    }
  }, [discussion])

  return (

    <>
      <Typography><strong>Participation: </strong> {numStudentsResult} students participated. The
        average participation was {avgPartResult} posts per student.</Typography>
      <Typography variant="h5"><strong>Hall of Fame:</strong> </Typography>
      <ul>
        <li> {maxNumPostsStudent} - Made {maxNumPostsResult} posts on the discussion forum.</li>
        <li>X - Connected a lot of unconnected peers
          (Hightest betweeness centrality = Y)
        </li>
        <li>{maxClosenessCoStudent} - Exhibited maximum leadership and teamwork
          qualities (Highest closeness centrality = {maxClosenessCoResult})
        </li>
        <li>{maxWordCountStu} - Expressed their opinion in a very articulate
          manner (using {maxWordCount} words)
        </li>
        <li> {maxClusterCoStudent} - Friendliest neighbor in the network
          (Maximum Clustering Coefficient = {maxClusterCoResult})
        </li>
      </ul>
    </>

  )


}

// this function formats the messages
function reformat(message) {
  // regex for removing HTML tags
  const removeHTML = new RegExp(/<(.|\n)*?>/g);
  // regex for removing punctuations
  const removePunc = new RegExp(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g);
  // regex for removing non-breaking space
  const removeSpace = new RegExp(/\bnbsp\b/g);
  // regex for removing undefined variables
  const removeUndef = new RegExp('undefined');
  // regex for removing \n
  const removeNonB = new RegExp(/(\r\n|\n|\r)/gm);

  // removes any HTML tags, punctuations, and unnecessary content
  message = message.replace(removeHTML, ' ');
  message = message.replace(removeNonB, " ");
  message = message.replace(removeSpace, '');
  message = message.replace(removePunc, '');
  message = message.replace(removeUndef, '');
  message = message.toLowerCase();
  // returns the messages
  return message;

}