
import canvasFetch from "providers/canvasFetch"
const discussionRequests = async function(props, courseSnapshot){
    
        //add each request to the list
        console.log(courseSnapshot)

        //initial request for all discussions
        const discussions = await canvasFetch("courses/" + props.course_id + "/discussion_topics", props.access_token)

        courseSnapshot.set("courses/" + props.course_id + "/discussion_topics" , discussions)

        for (const discussion of discussions) {
                    courseSnapshot.set("courses/" + props.course_id + "/discussion_topics/" + discussion.id + "/view", await canvasFetch("courses/" + props.course_id + "/discussion_topics/" + discussion.id + "/view", props.access_token))
                    courseSnapshot.set("courses/" + props.course_id + "/discussion_topics/" + discussion.id, await canvasFetch("courses/" + props.course_id + "/discussion_topics/" + discussion.id, props.access_token))
                };
            
    
    
        return courseSnapshot
    }
    
    export default discussionRequests