import readCourseSnapshot from "./courseSnapshot/readCourseSnapshot";
import { getTheCourseSnapshot } from "./courseSnapshot/courseSnapshot";

/**
 * This is a wrapper class for making a request to the canvas API to standardize the form of the request.
 * @param {string} apiPath Path ending for accessing at the api.
 * @param {string} access_token The access token for use with the api.
 * @returns {Promise<Object>}
 */
const canvasFetch = function (apiPath, access_token) {

  var courseSnapshot = getTheCourseSnapshot()
  //check to see if reading from a file
  if (courseSnapshot === null || courseSnapshot === undefined) {
    //console.log("no course snapshot")
  }
  else {
    console.log("course snapshot " + courseSnapshot)
    console.log('reading from course snapshot for ' + apiPath);
    return new Promise((resolve, reject) => resolve(readCourseSnapshot(apiPath)))
  }

  const reqHeaders = {
    "Authorization": "Bearer " + access_token,
    "Accept": "application/json+canvas-string-ids"
  }
  const headers = new Headers(reqHeaders);
  const options = {
    headers: headers,
    method: "GET"
  }

  const request = new Request(' https://us-central1-canvas-analytics-40160.cloudfunctions.net/canvasCors/' + apiPath)

  return new Promise((resolve, reject) => {
    fetch(request, options).then(response => {
      if (response.status === 200 || response.status === 304) {
        response.json()
            .then(value => {
              resolve(value);
            })
            .catch(reason => {
              reject(reason);
            })
      }
      else {reject(response.status)}
    })
    .catch(reason => {
      reject(reason);
    })
  })
}

export default canvasFetch;
