import { Link, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import './Home.css'
import canvasFetch from "providers/canvasFetch";
import DiscussionSelection from "components/DiscussionSelection";
import { Box } from "@mui/system";
import TempAuthInput from "../../components/TempAuthInput";
import { Button, ButtonGroup, Typography } from "@mui/material";
import exportCourseSnapshot from "providers/courseSnapshot/exportCourseSnapshot";
import importCourseSnapshot from "providers/courseSnapshot/importCourseSnapshot";
import SurveySelection from "components/SurveySelection";
import WorkMarkLogo from "assets/512px/Logo-WordMark.png";

//const course_id = "127210000000003374";

export default function Home() {

    document.title = "Home"

    const [course_id, authTokenObject, selectedDataView, selectedDatumObject, courseSnapshot] = useOutletContext(); // No specific data is available for this view so no data Object is loaded here

    // In this case, we get the access token for the user
    const [loadQuizzes, setLoadQuizzes] = useState(false);
    const [quiz, setQuiz] = useState([]);

    // const [discussions, setDiscussions] = useState([]);
    // useEffect(() => {
    //     if (authTokenObject.validAccessToken) {
    //         canvasFetch("courses/" + course_id + "/discussion_topics/", authTokenObject.accessToken)
    //             .then(value => {
    //                 value.forEach(element => {
    //                     setDiscussions(v => ([...v, element]))
    //                 });
    //             });
    //     }
    // }, [authTokenObject.validAccessToken, courseSnapshot])

    // useEffect(() => {
    //     // load in all the graded surveys from the course
    //     if (loadQuizzes === false) {
    //         setLoadQuizzes(true);

    //         // GET quizzes
    //         canvasFetch("courses/" + course_id + "/quizzes", authTokenObject.accessToken)
    //             .then(value => {

    //                 // console.log(value);
    //                 value.forEach(element => {

    //                     // grab only the graded surveys
    //                     if (element.quiz_type === "graded_survey") {
    //                         setQuiz(v => ([...v, element]));
    //                     }

    //                 });

    //             });

    //         // // GET students
    //         // canvasFetch("courses/" + course_id + "/students", accessToken)
    //         //     .then(value => {

    //         //         value.forEach(element => {

    //         //             // exclude the Test Student
    //         //             if (element.name !== "Test Student") {
    //         //                 setStudentID(v => ([...v, element.id]));
    //         //             }

    //         //         })

    //         //     });

    //     }
    // }, [])
    //get the access token
    //In this case, the given access token is valid and we should load the index data view of the app

    //if token is valid, load the app
    console.log(`Data:\t${selectedDatumObject == null}`);
    return (
        <Box className="App" sx={{ width: "70vw" }}>
            {selectedDatumObject && selectedDataView === "DiscussionView" && // Converting in to array at this level, we do not pass in agregate ever right now.
                <DiscussionSelection access_token={authTokenObject.accessToken} course_id={course_id} discussions={[selectedDatumObject]} title={selectedDatumObject.title} singleDiscussion={true} />
            }
            {selectedDatumObject && selectedDataView === "QuizView" &&
                <SurveySelection access_token={authTokenObject.accessToken} course_id={course_id} assignment_id={selectedDatumObject.assignment_id} quiz_id={selectedDatumObject.id} survey_title={selectedDatumObject.title} />
            }
            {(selectedDatumObject == null) &&
                <>
                    <Typography sx={{
                        backgroundColor: (theme) => {return theme.palette.secondary.main},
                        padding: `8px`,
                        textAlign: `center`,
                        /* color: `#7C7C7C` */
                        color: `#FFFFFF` // white text
                    }} variant="h4" component="div">Please select a {selectedDataView === 'DiscussionView' ? "discussion" : "quiz"} to view its data</Typography>
                    <div style={{maxHeight: '512px'}}>
                        <img src={WorkMarkLogo} alt='Canvas Analytics App Logo' style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '12px', maxWidth: '55%', maxHeight: '100%'}} />
                    </div>
                </>
            }
            {/* <DiscussionSelector access_token={accessToken} course_id={course_id} /> */}
        </Box>
    );

}