var courseSnapshot = null;

const setTheCourseSnapshot = function(theCourseSnapshot) {
    courseSnapshot = theCourseSnapshot
}

const getTheCourseSnapshot = function() {
    return courseSnapshot
}

export {setTheCourseSnapshot, getTheCourseSnapshot}