// imports
import React, { useEffect, useState } from "react";
import canvasFetch from "providers/canvasFetch";
import WordCloud from "react-d3-cloud";
import './wordCloud.css';
import { removeStopwords, eng, _123 } from 'stopword'
import { Refresh } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const GetWords = function ({ discussion }) {

    // stateful wordlist
    const [wordList, setWordList] = useState();
    // holder for the messages String
    let messages = "";
    // holder for the messages array
    let messageArr = [];


    useEffect(() => {
        if (discussion) {
            setWords()
        }
    }, [discussion])

    function setWords() {
        // course data
        let data = discussion.view;

        // iterates through the course data
        data.forEach(element => {

            // finds the messages
            if (element.message && !element.deleted) {
                // removes the HTML and new lines
                element.message = reformat(element.message);
                // console.log(element.message);

                // add message to messages
                messages += element.message + " ";
            }

            // finds the recent replies
            if (element.replies) {
                // iterates through the recent replies
                element.replies.forEach(reply => {
                    if (reply.message && !reply.deleted) {
                        // removes the HTML and new lines
                        reply.message = reformat(reply.message);

                        // add reply to messages
                        messages += reply.message + " ";
                    }
                })
            }

        });

        // set messageArr to message array
        messageArr = messages.split(' ');

        // remove empty objects
        messageArr = messageArr.filter(value => Object.keys(value).length !== 0);

        // remove the stop words
        let parsedMessageArr = removeStopwords(messageArr, [...customStopWords, ...eng, ..._123]);

        // create key/value pair for word occurrences
        let newWordList = wordOccur(parsedMessageArr);

        // removes duplicate word objects
        newWordList = removeDupes(newWordList);

        setWordList(newWordList);
    }

    return (<>
        {// sets the display's state to null
            !wordList ? null :
                <>
                    <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <IconButton color="primary" onClick={setWords}>
                            <Refresh />
                        </IconButton>
                    </div>
                    <word-cloud-div>
                        <WordCloud
                            data={wordList}
                            width={400}
                            height={200}
                            font="Arial"
                            fontSize={(word) => word.value * 20}
                            fontWeight="bold"
                            spiral="rectangular"
                            rotate={(word) => word.value * 10}
                            padding={5}
                            random={Math.random}
                        />
                    </word-cloud-div>
                </>
        }
    </>)
}

export default GetWords;

// custom list of stopwords
const customStopWords = ['best', 'guy', 'idk', 'just', 'back', 'brings', 'no', 'feel', 'feels', 'lot', 'really', 'thats', 'cool', 'ill', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'about', 'above', 'after', 'again', 'against', 'all', 'am', 'an', 'and', 'any', 'are', "aren't", 'as', 'at', 'be', 'because', 'been', 'before', 'being', 'below', 'between', 'both', 'but', 'by', "can't", 'cannot', 'could', "couldn't", 'did', "didn't", 'do', 'does', "doesn't", 'doing', "don't", 'down', 'during', 'each', 'few', 'for', 'from', 'further', 'had', "hadn't", 'has', "hasn't", 'have', "haven't", 'having', 'he', "he'd", "he'll", "he's", 'her', 'here', "here's", 'hers', 'herself', 'him', 'himself', 'his', 'how', "how's", 'i', "i'd", "i'll", "i'm", "i've", 'if', 'in', 'into', 'is', "isn't", 'it', "it's", 'its', 'itself', "let's", 'me', 'more', 'most', "mustn't", 'my', 'myself', 'nor', 'not', 'of', 'off', 'on', 'once', 'only', 'or', 'other', 'ought', 'our', 'ours\tourselves', 'out', 'over', 'own', 'same', "shan't", 'she', "she'd", "she'll", "she's", 'should', "shouldn't", 'so', 'some', 'such', 'than', 'that', "that's", 'the', 'their', 'theirs', 'them', 'themselves', 'then', 'there', "there's", 'these', 'they', "they'd", "they'll", "they're", "they've", 'this', 'those', 'through', 'to', 'too', 'under', 'until', 'up', 'very', 'was', "wasn't", 'we', "we'd", "we'll", "we're", "we've", 'were', "weren't", 'what', "what's", 'when', "when's", 'where', "where's", 'which', 'while', 'who', "who's", 'whom', 'why', "why's", 'with', "won't", 'would', "wouldn't", 'you', "you'd", "you'll", "you're", "you've", 'your', 'yours', 'yourself', 'yourselves'];

// this function formats the messages
function reformat(message) {
    // regex for removing HTML tags
    const removeHTML = new RegExp(/<(.|\n)*?>/g);
    // regex for removing punctuations
    const removePunc = new RegExp(/[?.,\/#!$%\^&\*;:{}=\-_`~()]/g);
    // regex for removing non-breaking space
    const removeSpace = new RegExp(/\bnbsp\b/g);
    // regex for removing undefined variables
    const removeUndef = new RegExp('undefined');
    // regex for removing \n
    const removeNonB = new RegExp(/(\r\n|\n|\r)/gm);

    // removes any HTML tags, punctuations, and unnecessary content
    message = message.replace(removeHTML, ' ');
    message = message.replace(removeNonB, " ");
    message = message.replace(removeSpace, '');
    message = message.replace(removePunc, '');
    message = message.replace(removeUndef, '');
    message = message.toLowerCase();
    // returns the messages
    return message;

}

// object for words and their occurrences
function wordObj(text, value) {
    this.text = text;
    this.value = value;
}

// this function counts the occurrences of each word and creates a key/value pair
function wordOccur(array) {

    // holder for the word object
    let wordsList = [];

    // iterates through the array to count each word occurrence
    for (let i = 0; i < array.length; i++) {
        // if the word is not in the list
        if (!(wordsList.hasOwnProperty(array[i]))) {
            // add the new word to the array with 0 value
            wordsList.push(new wordObj(array[i], 0));
        }
        // iterates through the wordsList to increment values
        wordsList.forEach(element => {
            // increments the value of recurring words
            if (array[i] === element.text) {
                ++element.value;
            }
        })
    }
    return wordsList;
}

// removes duplicate objects from the list
function removeDupes(list) {
    list = list.filter((val, index, self) => index === self.findIndex((t) => (t.text === val.text)))
    return list;
}