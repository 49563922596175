//Contains posts from all discussions
const constGraphObj = {
    "nodes": [
        {
            "id": "rootPost 0: Chistmas Test",
            "group": 2
        },
        {
            "id": "Josh Osmanski",
            "group": 1
        },
        {
            "id": "rootPost 1: demo test",
            "group": 2
        },
        {
            "id": "Viji Ramasamy",
            "group": 1
        },
        {
            "id": "rootPost 2: Test 2 Electric Boogaloo",
            "group": 2
        },
        {
            "id": "rootPost 3: test discussion",
            "group": 2
        },
        {
            "id": "Jason Prado",
            "group": 1
        },
        {
            "id": "rootPost 4: Virtual Learning",
            "group": 2
        },
        {
            "id": "Nicholas Molina",
            "group": 1
        },
        {
            "id": "Kyle Johnson",
            "group": 1
        },
        {
            "id": "Noah Bloom",
            "group": 1
        },
        {
            "id": "rootPost 5: Hello there... ",
            "group": 2
        }
    ],
    "links": [
        {
            "source": "Josh Osmanski",
            "target": "rootPost 0: Chistmas Test",
            "value": 1
        },
        {
            "source": "Josh Osmanski",
            "target": "Josh Osmanski",
            "value": 1
        },
        {
            "source": "Josh Osmanski",
            "target": "rootPost 1: demo test",
            "value": 1
        },
        {
            "source": "Viji Ramasamy",
            "target": "rootPost 1: demo test",
            "value": 1
        },
        {
            "source": "Josh Osmanski",
            "target": "rootPost 2: Test 2 Electric Boogaloo",
            "value": 1
        },
        {
            "source": "Jason Prado",
            "target": "rootPost 3: test discussion",
            "value": 1
        },
        {
            "source": "Viji Ramasamy",
            "target": "rootPost 3: test discussion",
            "value": 1
        },
        {
            "source": "Josh Osmanski",
            "target": "rootPost 4: Virtual Learning",
            "value": 1
        },
        {
            "source": "Nicholas Molina",
            "target": "rootPost 4: Virtual Learning",
            "value": 1
        },
        {
            "source": "Kyle Johnson",
            "target": "rootPost 4: Virtual Learning",
            "value": 1
        },
        {
            "source": "Noah Bloom",
            "target": "rootPost 4: Virtual Learning",
            "value": 1
        },
        {
            "source": "Kyle Johnson",
            "target": "Viji Ramasamy",
            "value": 2
        },
        {
            "source": "Josh Osmanski",
            "target": "Viji Ramasamy",
            "value": 2
        },
        {
            "source": "Viji Ramasamy",
            "target": "rootPost 5: Hello there... ",
            "value": 1
        },
        {
            "source": "Jason Prado",
            "target": "rootPost 5: Hello there... ",
            "value": 1
        },
        {
            "source": "Josh Osmanski",
            "target": "Kyle Johnson",
            "value": 1
        },
        {
            "source": "Kyle Johnson",
            "target": "rootPost 5: Hello there... ",
            "value": 1
        },
        {
            "source": "Josh Osmanski",
            "target": "rootPost 5: Hello there... ",
            "value": 2
        }
    ]
}
//Find number of students
export const numStudents = function (graph) {
    return graph.nodes.filter(val => val.group === 1).length
}

//Find the average number of messages made per student
export const avgPart = function (graph) {
    let totalMessages = graph.links.reduce((acc, curr) => { return acc + curr.value }, graph.links[0].value)
    return totalMessages / numStudents(graph)
}

//Find the number of edges
export const maxNumEdge = function(graph) {
    return (graph.nodes.length - 2 * (graph.nodes.length - 2 - 1)) / 2
}

//Find the cluster coefficent
export const maxClusterCo = function (graph) {
    let actEdge = 0;
    let tempEdge = 0;
    let clusterCoStu = "";
    for (let i = 0; i < graph.nodes.length; i++) {
        for (let j = 0; j < graph.links.length; j++) {
            if (graph.nodes[i].id === graph.links[j].target && graph.nodes[i].group === 1) {
                tempEdge += 1
            }
            if (tempEdge > actEdge) {
                actEdge = tempEdge;
                clusterCoStu = graph.nodes[i].id
            }
        }
    }
    return {
        result: actEdge / tempEdge,
        student: clusterCoStu
    }
}

//Find the most posts made by a student
export const maxPosts = function (graph) {
    let maxNumPosts = 0;
    let maxNumStu = "";
    let tempNumPost = 0;
    for (let i = 0; i < graph.nodes.length; i++) {
        tempNumPost = 0
        for (let j = 0; j < graph.links.length; j++) {
            if (graph.nodes[i].id == graph.links[j].source) {
                tempNumPost += 1
            }
            if (tempNumPost > maxNumPosts) {
                maxNumPosts = tempNumPost
                maxNumStu = graph.nodes[i].id
            }
        }
    }
    return {
        result: maxNumPosts,
        student: maxNumStu
    }
}

//Find the closeness Centrality
export const maxClosenessCo = function(graph = constGraphObj) {
    let distance = 0;
    let closenessCoStu = "";
    let numConnect = 0;
    let maxClosenessCo = 0;
    let tempClose = 0;

    for (let i = 0; i < graph.nodes.length; i++)
    {
      tempClose = 0
      for (let j = 0; j < graph.links.length; j++)
      {
        if (graph.nodes[i].id == graph.links[j].target && graph.nodes[i].group == 1)
        {
          tempClose += 1
        }
        if (tempClose > numConnect)
        {
          numConnect = tempClose
          closenessCoStu = graph.nodes[i].id
          distance = 1
        }
      }
    }
    maxClosenessCo = 1 / distance

    return {
        result: maxClosenessCo,
        student: closenessCoStu
    }
}
