/**
 * This is a class for reading the course snapshot data from a json object. This is needed to read the mapped data stored in a file.
 * This is used to make the api requests to a local file, instead of to the canvas api directly
 * 
 * @returns {Map} courseSnapshot of the data from the file
 */

import {setTheCourseSnapshot} from "./courseSnapshot";

const importCourseSnapshot = function (props,setCourseSnapshot,setCourse_id) {

    props.setImportLoading(true) // this function is doing its thing after this point
    /* START WRITING CODE BELOW HERE */

    //TODO read actual data from file
    var file = window.fileInput.files[0];
    var textType = /text.*/;

    //if (file.type.match(textType)) {
    var reader = new FileReader();

    //var courseSnapshotJSON = `{"test": "data"}`;


    reader.onload = function(e) {
        var content = reader.result;
        //console.log("content " + content)
        //Here the content has been read successfuly
        //alert(content);

        const courseSnapshotObject = JSON.parse(content);
        const loadedcourseSnapshot = new Map(Object.entries(courseSnapshotObject));
        console.log("after " + loadedcourseSnapshot)
        console.log(loadedcourseSnapshot)

        setTheCourseSnapshot(loadedcourseSnapshot)
        //this line causes crazy amount of duplicates so dont run it
        //setCourse_id("Load in the new data pls :)")
        setCourse_id(loadedcourseSnapshot.get("course_id"))
        setCourseSnapshot(loadedcourseSnapshot)
    }

    reader.readAsText(file);

    /* STOP WRITING CODE BEYOND HERE */
    props.setImportLoading(false) // at this point this function should not be doing anything else
}

export default importCourseSnapshot;