
import canvasFetch from "providers/canvasFetch"
const surveyRequests = async function (props, courseSnapshot) {


    //add each request to the list
    console.log(courseSnapshot)

    //initial request for all quizzes
    const quizzes = await canvasFetch("courses/" + props.course_id + "/quizzes", props.access_token)
    courseSnapshot.set("courses/" + props.course_id + "/quizzes", quizzes)

    for (const quiz of quizzes) {
        courseSnapshot.set("courses/" + props.course_id + "/quizzes/" + quiz.id + "/statistics", await canvasFetch("courses/" + props.course_id + "/quizzes/" + quiz.id + "/statistics", props.access_token))
        courseSnapshot.set("courses/" + props.course_id + "/quizzes/" + quiz.id + "/submissions", await canvasFetch("courses/" + props.course_id + "/quizzes/" + quiz.id + "/submissions", props.access_token))
        //get the data for the submissions, ask Jason why it looks like this
        let includeArr = [
            'submission_history',
            'submission_comments',
            'rubric_assessment',
            'assignment',
            'visibility',
            'course',
            'user',
            'group',
            'read_status'
        ]
        let includeString = "?include[]=" + includeArr.reduce((acc, curr) => { return acc + "&include[]=" + curr });
        let subHistory = await canvasFetch("courses/" + props.course_id + "/assignments/" + quiz.assignment_id + "/submissions" + includeString, props.access_token);

        courseSnapshot.set("courses/" + props.course_id + "/assignments/" + quiz.assignment_id + "/submissions" + includeString, subHistory)
        
        //patch fix for other export function not working
        let quiz2Req ="courses/" + props.course_id + "/assignments/" + quiz.assignment_id + "/submissions?include[]=submission_history&include[]=user"
        let quiz2Resp = await canvasFetch(quiz2Req, props.access_token)
        courseSnapshot.set(quiz2Req,quiz2Resp)

    };



    return courseSnapshot
}

export default surveyRequests