/**
 * This is a class for reading the course snapshot data from a json object. This is needed to read the mapped data stored in a file.
 * This is used to make the api requests to a local file, instead of to the canvas api directly
 */
import makeAllSnapshotRequests from "./snapshotRequests/makeAllSnapshotRequests";

 const exportCourseSnapshot = async function (props) {

    props.setExportLoading(true) // this async function is doing its thing after this point
    /* START WRITING CODE BELOW HERE */

    //make the course snapshot
    const courseSnapshot = await makeAllSnapshotRequests(props)

    const courseSnapshotObject = Object.fromEntries(courseSnapshot);
    const courseSnapshotJSON = JSON.stringify(courseSnapshotObject);
    
    const blob = new Blob([courseSnapshotJSON],{type:'application/json'});
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = "courseSnapshot_" + props.course_id + ".CAcourseSnapshot";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    //TODO write json data to file

    /* STOP WRITING CODE BEYOND HERE */
    props.setExportLoading(false) // at this point this async function should not be doing anything else
}

export default exportCourseSnapshot;