import canvasFetch from "./canvasFetch";

//this function takes in data from the dicussion and formats them for use in the d3.js graph
function discussionGraph( discussionDataArr, discussionMetaDataArr) {


    let linkArray = [];
    let nodeArray = [];
    let theParticipants = [];

    if(!discussionMetaDataArr || discussionMetaDataArr.length === 0) {return null}

    for (let i = 0; i < discussionMetaDataArr.length; i++) {

        let rootData = discussionDataArr[i]
        let rootMetadata = discussionMetaDataArr[i]

        let discussionData = rootData.view;

        theParticipants.push(rootData.participants);

        //get all of the links
        let rootNode = 'rootPost ' + i + ': ' + rootMetadata.title;
        linkArray = getLinks(linkArray, discussionData, rootNode);

        nodeArray = getNodes(linkArray, nodeArray, rootNode);

    }

    //renaming function replaces all id numbers with student names
    for (let i = 0; i < theParticipants.length; i++) {
        replaceIDWithNames(nodeArray, linkArray, theParticipants[i]);

    }
    //console.log({ nodes: nodeArray, links: linkArray });

    return {nodes: nodeArray, links: linkArray};

}

//gets all the links in the discussion between posters
function getLinks(linkArray, discussionData, parentNode) {


    for (let i = 0; i < discussionData.length; i++) {

        let post = discussionData[i];
        //console.log(post);
        let isInDataArray = false;

        for (let j = 0; j < linkArray.length; j++) {

            if (linkArray[j].source === post.user_id && linkArray[j].target === parentNode) {
                linkArray[j].value = linkArray[j].value + 1;
                isInDataArray = true;
                break;
            }
            //if post is deleted, dont count it
            else if (post.deleted || !post.user_id) {
                //console.log(JSON.stringify(post) + 'skipped')
                isInDataArray = true;
                break;
            }

        }

        if (!post.user_id) {
            //console.log('WARNING: ' + JSON.stringify(post));
            //console.log(post.deleted)
        } else {
            linkArray = nestedNodes(linkArray, post);
        }


        if (!isInDataArray) {
            //fix to a logic error where deleted posts are 
            if (!post.user_id || !parentNode) {
                //console.log('WARNING: ' + JSON.stringify(post));
                //console.log(post.deleted)
            } else {
                linkArray.push({source: post.user_id, target: parentNode, value: 1});
            }
        }

    }


    //console.log(linkArray);
    return linkArray;
}

//recursive function that looks in the links for replies
function nestedNodes(linkArray, post) {

    if (post.replies) {
        let replies = post.replies;
        //console.log(replies);
        for (let i = 0; i < replies.length; i++) {
            linkArray = getLinks(linkArray, replies, post.user_id);
        }
    }

    return linkArray;
}

//this function gets all of the nodes representing the participants in the graph
function getNodes(linkArray, nodeArray, rootNode) {
    nodeArray.push({id: rootNode, group: 2});

    //source
    for (let i = 0; i < linkArray.length; i++) {
        let isInNodeArr = false;

        for (let j = 0; j < nodeArray.length; j++) {
            //console.log(nodeArray[j].id + ' ' + participantsArr[i].id)
            if (nodeArray[j].id === linkArray[i].source) {
                isInNodeArr = true;
                break;
            }
        }
        if (!isInNodeArr) {
            nodeArray.push({id: linkArray[i].source, group: 1});

        }

    }

    //target
    for (let i = 0; i < linkArray.length; i++) {
        let isInNodeArr = false;

        for (let j = 0; j < nodeArray.length; j++) {
            //console.log(nodeArray[j].id + ' ' + participantsArr[i].id)
            if (nodeArray[j].id === linkArray[i].target) {
                isInNodeArr = true;
                break;
            }
        }
        if (!isInNodeArr) {
            nodeArray.push({id: linkArray[i].target, group: 1});

        }

    }
    return nodeArray;

}

//replaces all id numbers from the post with students names
function replaceIDWithNames(nodeArray, linkArray, participantsArr) {
    for (let i = 0; i < nodeArray.length; i++) {
        let nodeItem = nodeArray[i];
        for (let j = 0; j < participantsArr.length; j++) {
            let parItem = participantsArr[j];
            if (nodeItem.id === parItem.id) {
                nodeItem.id = parItem.display_name;
                break;
            }
        }
    }
    //source
    for (let i = 0; i < linkArray.length; i++) {
        let linkItem = linkArray[i];
        for (let j = 0; j < participantsArr.length; j++) {
            let parItem = participantsArr[j];
            if (linkItem.source === parItem.id) {
                linkItem.source = parItem.display_name;
                break;
            }
        }
    }
    //target
    for (let i = 0; i < linkArray.length; i++) {
        let linkItem = linkArray[i];
        for (let j = 0; j < participantsArr.length; j++) {
            let parItem = participantsArr[j];
            if (linkItem.target === parItem.id) {
                linkItem.target = parItem.display_name;
                break;
            }
        }
    }

}


export default discussionGraph;