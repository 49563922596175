// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Adding necessary sdks including auth
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, collection, getDocs, connectFirestoreEmulator } from "firebase/firestore";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBoOSBvtRN6qg5gG2yArgvpYXY06_2IUpw",
  authDomain: "canvas-analytics-40160.firebaseapp.com",
  projectId: "canvas-analytics-40160",
  storageBucket: "canvas-analytics-40160.appspot.com",
  messagingSenderId: "555221000372",
  appId: "1:555221000372:web:0f774df5db9f8ea26c37e1"
};

// This block is left in to explicitly fix google analytics cookie issues
// and prevent the cookies from being blocked in the future.
// github issue reference: https://github.com/firebase/firebase-js-sdk/issues/4123
window.dataLayer = window.dataLayer || [];
window.gtag = function () { window.dataLayer.push(arguments); }

window.gtag("config", firebaseConfig.measurementId, {
  cookie_domain: window.location.hostname,
  cookie_flags: "SameSite=None;Secure",
});

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);

export function firebaseQuery(folder) {
  return new Promise(async (resolve, reject) => {
    const theQuery = await getDocs(collection(db, folder));
    let theArr = [];
    theQuery.forEach(doc => {
      theArr.push(doc.data());
    })
    resolve(theArr);
  });
}

if (process.env.REACT_APP_ENABLE_EMULATORS === "true") {
  connectAuthEmulator(auth, "http://localhost:9099")
  connectFirestoreEmulator(db, "localhost", 9000)
}