import canvasFetch from "../../providers/canvasFetch";
import 'routes/Home/Home.css'
import { Button, Input } from "@mui/material";

export default function TempAuthInput(props) {

    document.title = "Access Token Needed"

    // Renders a temporary authorization view for the user to manually enter in their access token from Canvas
    //TODO: Refactor this into an automatic OAuth workflow with Canvas
    return(
        <div className={"App"} id={"accessTokenScreen"}>
            <form
                className={"accessTokenForm"}
                onSubmit={e => {
                    e.preventDefault()
                    canvasFetch("courses/", props.authTokenObject.accessToken)
                        .then(() => { props.authTokenObject.setValidAccessToken(true); localStorage.setItem("access_token", props.authTokenObject.accessToken); localStorage.setItem("valid_access_token", "true"); })
                        .catch(reason => { alert("Your access token was not valid try again please: " + reason) })
                }}
            >
                <label>
                    Please input your access token:
                    <input id={"access_token"}
                           name={"access_token"}
                           placeholder={"access_token"}
                           type={"text"}
                           value={props.authTokenObject.accessToken}
                           onChange={e => { props.authTokenObject.setAccessToken(e.target.value) }}
                    />
                </label>
                <Button variant="contained" type={"submit"} value={"Submit"}  sx={{margin: "0 auto"}}>Login</Button>
            </form>
        </div>
    )
}