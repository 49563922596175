// imports
import React, { useState } from "react";
import { CsvToHtmlTable } from 'react-csv-to-table';
import { Parser, transforms } from "json2csv";
import './table.css';
import './disc.css';
import canvasFetch from "providers/canvasFetch";
import { CSVLink } from "react-csv";
import { Typography } from "@mui/material";

const {unwind} = transforms

const TableCSV = function ({ discussion}) {


    return (
        <>
            {discussion && <>
                {/* <CSVLink data={getCSV(filterDiscussion(discussion)).replace(/"/g, '')} target="_blank" filename={courseTitle + ".csv"}>Download Data</CSVLink> */}
                <CsvToHtmlTable tableClassName={'csvTable'} data={getCSV(filterDiscussion(discussion))} csvDelimiter={","} />
            </>}


        </>
    )
}

export default TableCSV;


const filterDiscussion = function (discussion) {

    // regex to remove HTML
    const removeHTML = /<[^>]+>/g;
    // regex to remove new lines
    const removeNewLine = /(\r\n|\n|\r)/gm;
    // regex to remove &nbsp;
    const removeNBSP = /(&nbsp;)*/g;
    // regex to remove commas
    const removeCommas = /,/g;

    /** json from the canvasFetch
     * @type {Object[]}
     */
    let data = discussion.view;

    // iterates through the discussion.json file
    data = data.map(element => {

        // finds the messages
        if (element.message && !element.deleted) {
            // removes the HTML and new lines
            element.message = element.message.replace(removeHTML, '');
            element.message = element.message.replace(removeNewLine, ' ');
            element.message = element.message.replace(removeNBSP, '');
            element.message = element.message.replace(removeCommas, '');
        }

        // finds the recent replies
        if (element.replies) {
            // iterates through the recent replies
            element.replies = element.replies.map(reply => {
                if (!reply.deleted) {
                    // removes the HTML and new lines
                    reply.message = reply.message.replace(removeHTML, '');
                    reply.message = reply.message.replace(removeNewLine, ' ');
                    reply.message = reply.message.replace(removeNBSP, '');
                    reply.message = reply.message.replace(removeCommas, '');
                }
                return reply
            })
        }

        return element

    });

    data = data.filter(element => element.user_id);

    return data
}

const getCSV = function(discussion) {
    // taking in certain keys and changing their labels
    const fields = [
        {
            label: 'Discussion Post ID',
            value: 'id'
        },
        {
            label: 'Poster ID',
            value: 'user_id'
        },
        {
            label: 'Timestamp',
            value: 'created_at'
        },
        {
            label: 'Message',
            value: 'message'
        },
        {
            label: 'Replies',
            value: 'replies.message'
        }
    ];

    // iterates through recent_replies to get message
    const transforms = [unwind({ paths: ['replies'] })];
    // parses the fields and takes in the transforms to create the csv
    const jsonToCSV = new Parser({ fields, transforms });

    // creates the csv with the given .JSON
    return jsonToCSV.parse(discussion);
}