import canvasFetch from "./canvasFetch";

async function surveySubmissions(course_id, assignment_id, quiz_id, token) {

    // array for student id and quiz data
    let answers = [];

    // testing the submission_history for submission_data, sadly still null
    let includeArr = [
        'submission_history',
        'submission_comments',
        'rubric_assessment',
        'assignment',
        'visibility',
        'course',
        'user',
        'group',
        'read_status'
    ]
    // concatenate subHistory
    let includeString = "?include[]=" + includeArr.reduce((acc, curr) => {
        return acc + "&include[]=" + curr
    });

    // get the submissions
    let subHistory = await canvasFetch("courses/" + course_id + "/assignments/" + assignment_id + "/submissions" + includeString, token);
    // console.log(subHistory)

    // statistics for each quiz
    let statistics = await canvasFetch("courses/" + course_id + "/quizzes/" + quiz_id + "/statistics", token);

    // student submissions
    let submissions = await canvasFetch("courses/" + course_id + "/quizzes/" + quiz_id + "/submissions", token);

    // array of student submissions ids
    let studentSubmissions = submissions.quiz_submissions.map(element => element.user_id);

    // remove the test students
    studentSubmissions = studentSubmissions.filter(removeTestStudent);

    // statistics data
    let statisticsData = statistics.quiz_statistics[0].question_statistics;

    // console.log(statisticsData)

    // regex(submissionData.question_text);

    // loop through submission data
    statisticsData.forEach(element => {

        // removes HTML and other unnecessary content
        element.question_text = reformat(element.question_text);

    });

    // add each student submission data to answers
    for (let i = 0; i < studentSubmissions.length; i++) {

        // student object
        let student = {};

        // add student object to the answers array
        student.user_id = studentSubmissions[i];
        // console.log(student)

        // loop through the statistics data
        for (let j = 0; j < statisticsData.length; j++) {

            // question object
            let question = statisticsData[j];
            // console.log(question)

            // if the question is multiple choice, true/false, short answer, or multiple answers
            if (question.question_type === "multiple_choice_question" || question.question_type === "true_false_question" || question.question_type === "short_answer_question" || question.question_type === "multiple_answers_question") {

                // array of answers
                let ans = question.answers;

                // loop through array of answers
                for (let m = 0; m < ans.length; m++) {

                    // each responses
                    let response = ans[m];

                    // omit the 0 responses
                    if (response.responses > 0) {

                        // finds the user, add to the student
                        if (response.user_ids.includes(student.user_id)) {
                            student[question.question_text] = response.text;
                        }
                    }
                }
            }

            // if the question is true/false
            if (question.question_type === "essay_question") {

                // question id
                let questionID = question.id;
                // console.log(questionID)

                // iterate through the submissions
                for (let m = 0; m < subHistory.length; m++) {

                    // student submission
                    let studentSubHist = subHistory[m];

                    // find the student's submission
                    if (studentSubHist.user_id === student.user_id) {

                        // get the student's submission_history
                        let subHist = studentSubHist.submission_history;

                        // get the submission data
                        subHist.forEach(data => {

                            // the submission data
                            let subData = data.submission_data;

                            // iterate through the submission data
                            subData.forEach(q => {

                                // find the questionID, get the answer
                                if (q.question_id === questionID) {

                                    // removes HTML and other unnecessary content
                                    q.text = reformat(q.text);

                                    // add the answer to the student
                                    student[question.question_text] = q.text;
                                }
                            })
                        });
                    }
                }
            }

        }
        // add student object to the answers array
        answers.push(student);
        // console.log(student)

    }

    // console.log(answers)

    return answers;
}

// this function formats the answers
function reformat(answers) {
    // regex to remove HTML
    const removeHTML = /<[^>]+>/g;
    // regex to remove commas
    const removeCommas = /,/g;
    // regex to remove &nbsp;
    const removeNBSP = /(&nbsp;)*/g;

    // removes any HTML tags, punctuations, and unnecessary content
    answers = answers.replace(removeHTML, '');
    answers = answers.replace(removeCommas, '');
    answers = answers.replace(removeNBSP, '');

    // returns the answers
    return answers;
}

// removes test students
function removeTestStudent(id) {
    return id < "127210000000000000";
}

export default surveySubmissions;