import {useEffect, useState} from "react";
import {Autocomplete, TextField} from "@mui/material";

export default function SearchAutoCompleteDataFilter(props) {

    const [searchText, setSearchText] = useState('') // Track the state of the search input text given by the user

    function applySearchDataFilter() {

        let dataToFilter = props.dataObject // All available discussions/quizzes/data
        let dataFiltered = [] // The resulting array Object of data (discussions or quizzes) that matched the given usr input search text

        dataToFilter.forEach( (datumObject, index) => {

            if (datumObject.title !== undefined) {
                if (datumObject.title.toLowerCase().includes( searchText.toLowerCase() )) { // if the search text does fuzzy match the discussion title...
                    dataFiltered.push(datumObject) // add the matched datum (discussion or quiz) to the filtered list of discussions/quizzes/data
                }
            }
        })

        return(dataFiltered) // An array Object of all discussions/quizzes/data that match in their title string with the search text string given by the user
    }

    useEffect(() => {

        let dataFiltered = applySearchDataFilter() // Applies the fuzzy search on all available discussions/quizzes/data in the dataObject

        props.setFilteredData(dataFiltered) // will update state and therefore will update what is displayed in the discussion/quiz/data button list (re-render the list already returned below)

    }, [searchText]) // When there is new search text (when it has been changed in any way),
    // apply the fuzzy search filter and limit shown discussions/quizzes/data in the discussion/quiz/data button list to
    // discussion/quiz/data tiles that match the search text given by the user.

    return( // The Autocomplete search bar for the specific data view loaded

        <Autocomplete
            onInputChange={ (event, value) => { // this represents the event fired every time the user modifies the TextField text

                props.setFilteredData([]) // Start with no results added yet

                setSearchText(value) // Prompt React to apply search text for filtering discussions

            }}
            onChange={ (event, value) => { // this represents the event fired only when the user picks an option

                props.dataObject.forEach( (datumObject, index) => {

                    if (datumObject.title === value) {

                        props.setSelectedIndex(datumObject.id) // make the clicked button selected
                        props.setSelectedDatumObject(datumObject) // set current discussion as selected discussion
                        props.setActiveRoute(`${props.dataLabel}View/${datumObject.id}`) // switch to the appropriate data view route (DiscussionView or QuizView) with discussion/quiz/data id context
                    }
                })
            }}
            id={`${props.dataLabel.toLowerCase()}-search-input`}
            freeSolo
            fullWidth
            sx={{ padding: `16px`, paddingTop: `0px`, paddingBottom: `6px` }}
            options={props.dataObject.map((option) => option.title)}
            /*options={["hello world", "cool title", "another cool title"]}*/
            renderInput={(params) => <TextField {...params} label={`Search ${props.dataLabel} Titles`}
            />}
        />
    )
}