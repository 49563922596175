// imports
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import SurveyCSV from "../SurveyCSV";
import WaterMarkLogo from "../../assets/512px/Logo-Color.png";
import SummaryOpenIcon from "@mui/icons-material/MenuOpen";

const SurveySelection = function ({ survey_title, access_token, course_id, assignment_id, quiz_id }) {

    // state for the displays
    const [display, setDisplay] = useState(false);

    // onClick displays the survey data
    const onClick = () => {
        display ? setDisplay(false) : setDisplay(true);
    }

    // displays a button for each survey
    // will display the CSV for each survey
    return (

        <Box className={"survey-button"} sx={{
            minWidth: "0",
            minHeight: '365px',
            backgroundImage: `url(${WaterMarkLogo})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 35px',
            backgroundSize: 'contain'
        }}>
            <Typography variant="h3" sx={{ fontWeight: "700", textAlign: "start" }}>Survey: {survey_title}</Typography>
            <Card sx={{
                backgroundColor: `transparent`,
                boxShadow: `0px 0px 0px 0px rgba(0,0,0,0.0)`
            }}>
                <CardContent sx={{

                    ".MuiPaper-root": {
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        "&.Mui-expanded": {
                            backgroundColor: 'rgba(255,255,255,1.0)'
                        }
                    },

                    ".MuiAccordionSummary-root": {
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        "&:hover": {
                            backgroundColor: (theme) => {return theme.palette.primary.light},
                            color: `#FFFFFF` //white text
                        }
                    },
                    ".MuiButtonBase-root.Mui-expanded": {
                        backgroundColor: (theme) => {return theme.palette.secondary.main},
                        color: `#FFFFFF` //white text
                    }
                }}>
                    <Accordion>
                        <AccordionSummary>
                            <Typography>
                                CSV Export and Table <SummaryOpenIcon sx={{color: `#FFFFFF`, position: `absolute`, right: `10px`}} />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{overflowX: "scroll"}}>
                                <SurveyCSV access_token={access_token} course_id={course_id} quiz_id={quiz_id} assignment_id={assignment_id} survey_title={survey_title} />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Box>

    )

}

export default SurveySelection;