import discussionRequests from "./discussionsRequests";
import surveyRequests from "./surveyRequests";
import canvasFetch from "providers/canvasFetch";

const makeAllSnapshotRequests = async function(props){

const courseSnapshot = new Map();

    //add each request to the list
    //not specific requests
    //set course id
    courseSnapshot.set("course_id", props.course_id);

    //set student data
    const students = await canvasFetch("courses/" + props.course_id + "/students", props.access_token)
    courseSnapshot.set("courses/" + props.course_id + "/students" , students)

    //set complicated data
    await discussionRequests(props, courseSnapshot);
    await surveyRequests(props, courseSnapshot);

    console.log(courseSnapshot)

    return courseSnapshot
}

export default makeAllSnapshotRequests