import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import reportWebVitals from 'reportWebVitals';

// Importing reactfire providers
import { AuthProvider, FirebaseAppProvider, FirestoreProvider } from "reactfire";

/**
 * Importing initialized firebase sdks,
 * allowing for optional emulator enabling later.
 * 
 * This is personal preference but I prefer to have all firebase declarations 
 * in a shared file so I intentionally firebaseApp and each sdk separate.
 * This is not how it is listed in reactfire docs but it works exactly the same as their solution.
 */
import { auth, firebaseApp, db } from "providers/firebase";

// Importing roboto font for mui
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// import material necessities 
import { createTheme, ThemeProvider } from '@mui/material';

//Create mui themes
const theme = createTheme({
  palette: {
    primary: {
      main: '#016836',
      // dark: will be calculated from palette.primary.main,
      light: '#309033'
    },
    secondary: {
      main: '#73A950',
      // dark: will be calculated from palette.secondary.main,
      light: '#9FD979'
    }

  }
})

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseApp={firebaseApp}>
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={db}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </FirestoreProvider>
      </AuthProvider>
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
