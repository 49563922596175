
import { Outlet, Route, Routes } from "react-router-dom";
import Layout from 'routes/Layout';

// Adding providers for react router dom to use throughout the app.
import { BrowserRouter } from "react-router-dom";
import Login from 'deprecated/Login';
import Dashboard from 'deprecated/Dashboard';
import Home from 'routes/Home';
import RequireAuth from 'deprecated/RequireAuth';
import TempAuthInput from "./components/TempAuthInput";
import DiscussionDataView from "./routes/DiscussionDataView";
import QuizDataView from "./routes/QuizDataView";
import {useState} from "react";

// TODO: Make the course_id be populated from the Canvas course context this web app is opened in from the launch URL response
// This should not be left hard-coded if this app is to be used in any other Canvas course than this ID
const course_id = "127210000000003374";

/**
 * We will use this as the base for our routes only and nothing else all layout
 * should be done with nested routes instead
 */
function App() {

    // useState values are taken from 'localStorage' which represents browser-specific persistent storage ACROSS sessions
    // access token state is updated here so that the app may use this across multiple different available nested routes in the Layout route
    const [accessToken, setAccessToken] = useState(localStorage.getItem("access_token") || "");
    const [validAccessToken, setValidAccessToken] = useState(localStorage.getItem("valid_access_token") === "true" || false);

    return (
        <div id='app'>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Layout authTokenObject={{
                        // authTokenObject stores all the useState values and functions about the access token for all your access token needs
                       // These state values are taken from persistent browser storage ACROSS browser sessions

                        // Access Token string given by the user manually
                        accessToken: accessToken,
                        setAccessToken(value) { setAccessToken(value) },
                        // Access Token boolean on whether or not given access token is valid
                        validAccessToken: validAccessToken,
                        setValidAccessToken(value) { setValidAccessToken(value) }

                    }} course_id={course_id}
                    />}>
                        <Route
                            index // Equivalent to saying the page content for '/'
                            element={<Home />}
                        />
                        {/* <Route
                            path='DiscussionView/:id' // ':id' becomes the id of a selected discussion
                            element={<DiscussionDataView />}
                        />
                        <Route
                            path='QuizView/:id' // ':id' becomes the id of a selected quiz
                            element={<QuizDataView />}
                        /> */}
                        <Route
                            path='*' // Providing a 404 page for '/' and thus the whole site.
                            element={<Home />}
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
