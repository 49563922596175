import React, {Component} from "react";
import './d3.css'

/**
 * D3Component is set to take in the element returned by a d3 based function and render it directly.
 * Consider it a wrapper class to simplify later rendering, it accepts the element as a prop along with an optional
 * id or a random id from 0-10000 if not set.
 */
class D3Component extends Component {

    constructor(props) {
        super(props);
        if (this.props.id) {
            this.state = {id: this.props.id}
        } else {
            this.state = {id: Math.floor(Math.random() * 10000)}
        }
    }

    componentDidMount() {
        const element = this.props.element;
        document.getElementById(this.state.id).append(element);
    }

    componentDidUpdate() {
        const element = this.props.element;
        const parent = document.getElementById(this.state.id)
        if (!parent.firstChild.isEqualNode(element)){
            parent.removeChild(parent.firstChild)
            parent.append(element);
        }
        
    }

    render() {
        return <div id={this.state.id} className={"d3graph"}/>
    }
}

export default D3Component;
